import React from 'react';
import './NavBar.css';
import logo from '../assets/images/Plogo.svg';
import { tabs, languages } from './constants';

const NavBar = ({activeTab, setActiveTab, lang, setLang}) => {
  const renderTabs = tabs.map(t =>
  <button key={t.key} className={`Tab ${activeTab === t.key ? 'active' : ''} ${t.key}`} onClick={() => setActiveTab(t.key)}>
    {t.text[lang]}
  </button>
  );
  const renderLang = languages.map((l, index) => <button key={index} className={`Lang ${lang === l.key ? 'active' : ''}`} onClick={() => setLang(l.key)}>{l.text}</button>);
  return (
    <div className={`NavBar ${activeTab === '' ? 'WelcomePage' : ''}`}>
      <div className="Tabs">
        <button className="Tab" onClick={() => setActiveTab('')}><img src={logo} className="PLogo" alt="logo"/></button>
        {renderTabs}
      </div>
      <div className="LangTabs">
        {renderLang}
      </div>
      
    </div>
  );
}

export default NavBar;