import React, { useContext } from 'react';
import './Section.css';
import g1 from '../assets/images/gDesign.png';
import g2 from '../assets/images/gTech.png';
import g3 from '../assets/images/gAbout.png';
import logoGroup1 from '../assets/images/design.png';
import logoGroup2 from '../assets/images/tech.png';
import logoGroup3 from '../assets/images/aboutUs.png';
import { texts } from './constants';
import LangContext from './LangContext';

const Section = ({ activeTab }) => {
  let image = g1;
  let logoGroup = logoGroup1;
  let imgClass = '';
  switch(activeTab) {
      case 'technology' :
        image = g2
        logoGroup = logoGroup2;
        break;
      case 'about-us' :
        image = g3;
        logoGroup = logoGroup3;
        imgClass = 'AboutUs';
        break;
      default :
  }

  const lang = useContext(LangContext);
  return (
    <div className="Section">
      <div className="Left">
        <img className={`${imgClass}`} src={image} alt="gorilla" />
      </div>
      <div className="Right">
        <div>
          <div className={`Header ${activeTab}`}>{texts[activeTab]['header'][lang]}</div>
          {texts[activeTab]['text'][lang]}
        </div>
        <div className="LogoGroup"><img src={logoGroup} alt="gorilla" /></div>
      </div>
    </div>
  );
}

export default Section;