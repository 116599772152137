import React from 'react';
import ContactUs from './ContactUs';
import Section from './Section';
import animation from '../assets/images/homeAnimation.gif';
import addressIcon from '../assets/images/aboutUs/address.png';
import telIcon from '../assets/images/aboutUs/telephone.png';
import faxIcon from '../assets/images/aboutUs/fax.png';
import webIcon from '../assets/images/aboutUs/website.png';
import emailIcon from '../assets/images/aboutUs/email.png';

export const sections = [
  {
    key: '',
    element: <div className="WelcomeSection"><img src={animation} alt="animation" /></div>,
  },
  {
    key: 'design',
    element: <Section activeTab='design' />,
  },
  {
    key: 'technology',
    element: <Section activeTab='technology' />,
  },
  {
    key: 'about-us',
    element: <Section activeTab='about-us' />,
  },
  {
    key: 'contact-us',
    element: <ContactUs />,
  },
];

export const tabs = [
  {
    key: 'design',
    text: { en : 'Design', tr: 'Tasarım'},
  },
  {
    key: 'technology',
    text: { en : 'Technology', tr: 'Teknoloji'},
  },
  {
    key: 'about-us',
    text: { en : 'About Us', tr: 'Hakkımızda'},
  },
  {
    key: 'contact-us',
    text: { en : 'Contact Us', tr: 'İletişim'},
  },
];

export const languages = [
  {
    key: 'en',
    text: 'En',
  },
  {
    key: 'tr',
    text: 'Tr',
  },
];

export const texts = {
  design: {
    header: {
      en: "<h1>Design</h1>",
      tr: "<h1>Tasarım</h1>",
    },
    text: {
      en: <div className="Text">
      <p>We design and develop the kinds of applications, games and websites that we <span className="technology">love</span> to use... beautiful, unique, fast, creative, and intuitive. Our applications instantly create a sense of excitement and anticipation. Over time they create a sense of confidence and security.</p>
      <p>Instead of simply replicating designs that already exist, we design and build <span className="about-us">unique</span>, alternative user experiences according to an application’s audience and needs. It is our work and our passion to see the vision of each application we develop and make that a <span className="design">reality</span>.</p>
      <p>At <span className="contact-us">Puente</span>, we’ve created an environment where people come first, where we celebrate <span className="orange">the freedom to try new things and think differently</span>. That environment has birthed passion and creativity, and that is what you will experience when you work with us.</p>
    </div>,
      tr: <div className="Text">
      <p>Tasarımın kullanıcıyı dönüştürme ve harekete geçirme gücü olmalıdır. Bizler bunun farkında olarak heyecan ve güven duygusu yaratan web ve mobil uygulamaları, oyunlar ve siteler tasarlayıp geliştiriyoruz. Çünkü bizler de zaten öyle uygulamalar kullanmayı <span className="technology">seviyoruz</span>.</p>
      <p>Zaten var olan tasarımları kopyalamak yerine, ihtiyaçlarınıza ve hedef kitlenize göre <span className="about-us">benzersiz</span>, alternatif kullanıcı deneyimleri tasarlıyor ve üretiyoruz. Geliştirdiğimiz her uygulamanın vizyonunu görmek ve <span className="design">gerçekleştirmek</span> bizim işimiz ve tutkumuzdur.</p>
      <p>Bizler <span className="contact-us">Puente’de</span>, <span className="orange">yeni şeyler deneme ve farklı düşünme özgürlüğünün</span> olduğu, insan odaklı bir ortam yarattık. Sizler de bizimle çalıştığınızda bu tutku ve yaratıcılığa şahit olacaksınız.</p>
    </div>,
    },
  },
  technology: {
    header: {
      en: "<h1>Technology</h1>",
      tr: "<h1>Teknoloji</h1>",
    },
    text: {
      en: <div className="Text">
      <p>We love engineering, we love learning and we love creating. The code we write is as beautiful as the user interfaces we design. We use the latest libraries and technologies and we write software that can be trusted ... fast, secure, stable.</p>
      <p>At <span className="contact-us">Puente</span>, there is no limit to what we can build: web and mobile applications,games; <span className="lightblue">Internet of Things</span>, <span className="orange">Machine Learning</span>, Artificial Intelligence, <span className="design">Natural Language Processing</span>, Embedded Systems, Data Mining… If we haven’t done it yet, we will soon, because we <span className="about-us">specialize in understanding and implementing new ideas.</span></p>
      <p>We bring ideas to life. We develop elegant solutions to complex problems through innovation and proper assessment.</p>
    </div>,
      tr: <div className="Text">
      <p>Bizler Puente’de öğrenmeyi ve yaratmayı, yani mühendisliği seviyoruz. İnsan gelişimine odaklı ilerlerken hem kendi fikirlerimizle, hem de yıllarca birlikte çalışarak inşa ettiğimiz ortaklarımızla kaliteli çözümler üretiyoruz. Bunu yaparken her zaman kendini kanıtlamış son teknoloji ve yöntemlerle çalışıp hızlı, güvenli ve iddialı yazılımlar üretiyoruz.</p>
      <p><span className="contact-us">Puente'de</span> yapabileceklerimizin sınırı yoktur: Web ve Mobil uygulamalar, oyunlar; <span className="lightblue">Internet of Things</span>, <span className="orange">Machine Learning</span>, Artificial İntelligence, <span className="design">Natural Language Processing</span>, Embedded Systems, Data Mining ve çok daha fazlası. Henüz yapamadığımız bir şey varsa bu değişecektir, çünkü <span className="about-us">yeni fikirleri anlamak ve uygulamak bizim uzmanlık alanımızdır.</span></p>
      <p>Bu projeleri geliştirirken yaratıcılığın aktığı, konuşmaların lafta kalmadığı, aksine gerçekçi bir yaklaşım ve planlamayla değerli fikirlerin hayata geçirildiği bir ortam sağlıyoruz.</p>
    </div>,
    },
  },
  'about-us': {
    header: {
      en: "<h1>About Us</h1>",
      tr: "<h1>Hakkımızda</h1>",
    },
    text: {
      en: <div className="Text">
      <p><span className="contact-us">Puente</span> is a vision focused on human development. Puente employees love their job even when faced with difficult tasks and situations, because through those tasks and situations they are developing into mature artists and/or engineers.</p>
      <p>At Puente, everyone is taught to think of their teammates before themselves. Instead of keeping track of mistakes and personal faults, <span className="technology">we celebrate personal success</span>. As an employee, you know that everyone else’s highest purpose is for you to grow. Because you are not competing or being compared with anyone else, you are free to excel, try new things, ask questions and even make mistakes.</p>
      <p>It is our aim to provide opportunities for whole and healthy lives, while building a bridge between the world of advancing technology and <span className="about-us">Turkey</span>.</p>
    </div>,
      tr: <div className="Text">
      <p><span className="contact-us">Puente</span>, insan gelişimine odaklı bir çalışma vizyonudur. Bize göre şirket başarısı için doğru ölçek, insanların hayatlarını ne kadar geliştirebildiğimizdir. Bizler Puente’de bu vizyonla ilerlersek, geriye kalan her şeyin zaten gerçekleşeceğine inanıyoruz.</p>
      <p>Puente’de kendinizden önce takım arkadaşınızı düşünmeniz öğretilir. Eksiklerin ve hataların hesabı tutulmaz, ama <span className="technology">her başarı kutlanır</span>. Soru sormaktan, eleştirilmekten, hata yapmaktan korkulmaz çünkü herkesin yegâne amacı, birlikte büyümemizdir. Biliriz ki zorluklar dayanma gücü, dayanma gücü de hiçbir eksiği olmayan yetkin mühendisler ve sanatçılar yaratır.</p>
      <p>Bizim amacımız, gelişen teknolojiler ve gelişen <span className="about-us">Türkiye</span> arasında bir köprü oluştururken insanların yaşam, bol yaşam tecrübe etmelerini sağlamaktır.</p>
    </div>,
    },
  },
};

export const contactUsText = {
  en: <div>
    <p>No matter the current exchange rate or economic situation, we are determined to <span className="about-us">lead strongly,</span> <span className="design">ambitiously</span> <span className="technology">and courageously,</span> grow <span className="contact-us">Puente</span> as a company and to establish personal growth and development as the general norm in our teams.</p>
    <p>We stand against any commonly accepted business practices that value profit over lives. In contrast, we believe our approach will prove to the market that people-first companies produce higher quality, longer lasting and more profitable products in the end.</p>
  </div>,
  tr: <div>
    <p>Bizler etrafımızdaki piyasa kur veya ekonomik durum <span className="contact-us">ne olursa olsun</span>; güçlü, <span className="design">iddialı</span> <span className="technology">ve cesur</span> bir şekilde <span className="about-us">öncülük ederek</span> büyümekte kararlıyız.</p>
    <p>Piyasanın ’Normal’ saydığı yanlışlara ve kar uğruna yapılan her türlü düzene ve arka yola karşı duran, sonunda hep iyilerin kazandığını gösteren bir şirket olmak istiyoruz.</p>
  </div>
}

export const contactInfo = [
  {
    logo: <img src={addressIcon} alt="address" />,
    text: <p>Aziziye Mah. Cinnah Cad. 72/15 Çankaya / Ankara</p>,
  },
  {
    logo: <img src={telIcon} alt="telephone" />,
    text: <p>+90 312 438 54 38</p>
  },
  {
    logo: <img src={faxIcon} alt="fax" />,
    text: <p>+90 312 438 54 24</p>
  },
  {
    logo: <img src={webIcon} alt="website" />,
    text: <p>www.puentedev.io</p>
  },
  {
    logo: <img src={emailIcon} alt="email" />,
    text: <p>info@puentedev.io</p>
  },
];