import React, { useContext } from 'react';
import GoogleMapReact from 'google-map-react';
import './ContactUs.css';
import { contactUsText, contactInfo } from './constants';
import LangContext from './LangContext';
import locationIcon from '../assets/images/aboutUs/mapLocation.png';

const center = {lat: 39.890456, lng: 32.855133};
const zoom = 18;
const MapLocation = () =><div><img src={locationIcon} alt="address" /></div>;

const ContactUs = () => {
  const renderContactInfo = contactInfo.map((c, index) =>
    <div key={index} className="Info">
      {c.logo}
      {c.text}
    </div>
  );

  const lang = useContext(LangContext);

  return (
    <div className="ContactUs">
      <div className="Top">
        <div className="Text">
          {contactUsText[lang]}
        </div>
        <div className="ContactInfo">
          <p>PUENTE YAZILIM & DESIGN</p>
          {renderContactInfo}
        </div>
      </div>
      <div className="Bottom">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCUPFib4yUVlmvC794Ycp7FJFuJcN5x5gM' }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          <MapLocation
            lat={39.890456}
            lng={32.855133}
          />
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default ContactUs;