import React, { useState } from 'react';
import './App.css';
import ReactFullpage from '@fullpage/react-fullpage';
import NavBar from './components/NavBar';
import { sections } from './components/constants';
import { LangProvider } from './components/LangContext';

function App() {
  const [ activeTab, setActiveTab ] = useState('');
  const [ lang, setLang ] = useState('en');

  const onLeave = (origin, destination, direction) => {
    setActiveTab(sections[destination.index].key);
  }

  const changeTab = (tab) => {
    setActiveTab(tab);
    const index = sections.findIndex(t => t.key === tab);
    window.fullpage_api.moveTo(index + 1);
  }
  
  const renderSections = sections.map(section =>
    section.element
    ? <div key={section.key} className="section">{section.element}</div>
    : <div key={section.key} className="section">Empty</div>
    );

  return (
    <LangProvider value={lang}>
      <div className="App">
        <NavBar
          activeTab={activeTab}
          setActiveTab={changeTab}
          lang={lang}
          setLang={setLang}
        />
        <ReactFullpage
          //fullpage options
          licenseKey={'68F56326-BFE6408F-A6839529-F8B15C70'}
          scrollingSpeed={700} /* Options here */
          bigSectionsDestination='top'
          paddingTop='50px'
          onLeave={(origin, destination, direction) => onLeave(origin, destination, direction)}

          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                {renderSections}
              </ReactFullpage.Wrapper>
            )
          }
          }
        />
      </div>
    </LangProvider>
  );
}

export default App;
